import Image from 'next/image'
import {
    FaTwitter,
    FaDiscord,
    FaHome
} from 'react-icons/fa'
import {
    SiRoblox
} from 'react-icons/si'
import { useRouter } from 'next/router'
import NoctovoLogo from "@/public/noctovo.svg"

const footerNav = [
    {
        name: 'Home',
        href: '/',
        icon: FaHome
    },
    {
        name: "Discord",
        href: "https://discord.gg/combatwarriors",
        icon: FaDiscord
    },
    {
        name: "Twitter",
        href: "https://twitter.com/CWRBLX",
        icon: FaTwitter
    },
    {
        name: "Roblox",
        href: "https://www.roblox.com/games/4282985734/Combat-Warriors",
        icon: SiRoblox
    }
]

export default function Footer({ buildId }) {
    const router = useRouter();

    return (   
        <footer className="rounded-lg shadow bg-black">
            <div className="w-full max-w-screen-xl mx-auto p-8 md:py-8">
                <div className="sm:flex sm:items-center sm:justify-between">
                    <a className="flex items-center sm:mb-0 cursor-pointer transition hover:scale-95">
                        <Image
                            src="/cw_logo.svg"
                            alt="App screenshot"
                            width={100}
                            height={100}
                            onClick={() => router.push('/')}
                        />
                    </a>
                    <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:-mb-12 dark:text-gray-400">
                        {footerNav.map((item) => (
                            <button className="px-4 py-2 text-white transition-colors duration-200 transform rounded-md hover:bg-white hover:bg-opacity-20" onClick={() => router.push(item.href)}>
                                {item.icon && (
                                    <item.icon className="w-5 h-5" aria-hidden="true" />
                                )}
                                {item.name && !item.icon && (
                                    item.name
                                )}
                            </button>
                        ))}
                    </ul>
                </div>
                <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                <span className="block text-xs text-gray-500 sm:text-center dark:text-gray-400">© {new Date().getFullYear()} Combat Warriors | Build {buildId}</span>
                <span className="flex justify-center items-center text-white mt-1 hover:underline cursor-pointer" onClick={() => window.open("https://noctovo.com/")}>
                    A{""}
                    <Image
                        src={NoctovoLogo}
                        alt="Noctovo Logo"
                        width={30}
                        height={30}
                        className="mx-2"
                    />
                    Project
                </span>
            </div>
        </footer>
    )
}